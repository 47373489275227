import React, {useContext, useState, useEffect} from 'react';
import Providers from '~src/components/Providers';
import {ContentView, PageTemplate, Modals, Article} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';

interface ICalculation1Props extends IPage {}

const ArticlePage: React.FC<ICalculation1Props> = ({location, navigate, path}) => {
  const {dispatch, articles} = useStoreon<IState, IEvents>('articles');
  const {
    pages: {articlePage, index},
  } = useContext(LocaleContext);

  const [articleID, setArticleID] = useState<number>(0);
  useEffect(() => {
    dispatch('articles/fetch-articles', {strategy: 'overwrite'});

    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('id') && typeof urlParams.get('id') === 'string') {
        // @ts-ignore
        setArticleID(+urlParams.get('id'));
      }
    }
  }, []);

  const articleHeader = articles.values.find(article => article.id === +articleID)?.title;
  const breadcrumbsHeader = articleHeader ? articleHeader : articlePage.header1;

  return (
    <Providers>
      <Seo
        title={articlePage.meta.title}
        description={index.meta.description}
        keywords={index.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...articlePage.breadcrumbs, breadcrumbsHeader]}
        breadcrumbsSource={articlePage.breadcrumbsSource}
      >
        <ContentView>
          <Article articleID={articleID} />
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default ArticlePage;
